<template>
    <CRow>
        <CCol>
            <strong>
                Advancer English Yönetim Paneline Hoşgeldiniz!
            </strong>
        </CCol>
    </CRow>
</template>

<script>

export default {
    name: 'Dashboard',
    components: {},
}
</script>

